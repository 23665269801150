<template>
  <v-container class="d-flex justify-center">
    <meta name="robots" content="noindex">
    <img src="../assets/image/404/404.jpg" width="80%"/>
  </v-container>
</template>

<script>

export default {
  name: 'App',

  data() {
    return {
      //
    }
  },
  metaInfo() {
    return {
      title:'یافت نشد',
      meta: [{
        vmid: 'robots',
        name: 'robots',
        content: 'noindex',
      }],
      link: [{rel: 'canonical', href: this.$store.state.url+'/404'}]
    }
  },
};
</script>
